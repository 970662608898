import { Link } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { range } from '../../utilities';

const Pagination = ({
  currentPage,
  total,
  baseURL = '/insights',
}: {
  currentPage: number;
  total: number;
  baseURL?: string;
}) => {
  if (total <= 1) {
    return null;
  }
  return (
    <div className="flex gap-[0.75rem] font-semibold">
      {currentPage - 2 >= 1 && (
        <>
          <Link className="pagination" to={`${baseURL}`}>
            1
          </Link>
          {currentPage - 2 > 1 && (
            <Link className="pagination points" to={`${baseURL}`}>
              ...
            </Link>
          )}
        </>
      )}
      {range(total, 1).map(
        (pageNumber) =>
          Math.abs(currentPage - pageNumber) < 2 && (
            <Link
              key={pageNumber}
              className={`pagination ${
                currentPage === pageNumber ? 'selected' : ''
              }`}
              to={pageNumber === 1 ? `${baseURL}` : `${baseURL}/${pageNumber}`}
            >
              {pageNumber}
            </Link>
          )
      )}
      {total >= currentPage + 2 && (
        <>
          {total > currentPage + 2 && (
            <Link className="pagination points" to={`${baseURL}`}>
              ...
            </Link>
          )}
          <Link className="pagination" to={`${baseURL}/${total}`}>
            {total}
          </Link>
        </>
      )}
    </div>
  );
};

export default Pagination;
