import * as React from 'react';
import { InsightPreview } from '../../types';
import NewsItem from './newsItem';
import Pagination from './pagination';

const Board = ({
  insights,
  currentPage,
  total,
}: {
  insights: InsightPreview[];
  currentPage: number;
  total: number;
}) => (
  <>
    <div className="grid grid-cols-2 gap-[5rem] pb-[5rem]">
      {insights.map(({ title, summary, slug, tags, image }, index) => (
        <div
          key={slug}
          className={index === 0 ? 'col-span-2' : 'col-span-2 lg:col-span-1'}
        >
          <NewsItem
            title={title}
            summary={summary}
            slug={slug}
            tags={tags}
            large={index === 0}
            image={image}
          />
        </div>
      ))}
    </div>
    <div className="mb-[8.75rem]">
      <Pagination total={total} currentPage={currentPage} />
    </div>
  </>
);

export default Board;
