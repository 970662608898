import * as React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import Tags from './tags';

const Header = ({ tags }: { tags: string[] }) => {
  return (
    <div className="text-black mt-[1.25rem]">
      <h1 className="mb-[3.75rem]">
        <Trans>Noteworthy Insights</Trans>
      </h1>
      <div className="mb-[3.75rem]">
        <hr className="border-black" />
        <div className="h-[3.375rem]">
          <Tags tags={tags} />
        </div>
        <hr className="border-black" />
      </div>
    </div>
  );
};

export default Header;
