import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Header from '../components/insights/header';
import Base from '../components/base';
import Board from '../components/insights/board';
import { InsightPreview } from '../types';
import { formatRawInsightPreview, onlyUnique } from '../utilities';
import Sides from '../components/ranges/sides';
import SEO from '../components/seo';

const Insights = ({
  data: {
    allMarkdownRemark: { nodes },
  },
  location,
  pageContext: { currentPage, total },
}: PageProps<
  Queries.insightListQueryQuery,
  { currentPage: number; total: number }
>) => {
  const insights: InsightPreview[] = nodes.map(formatRawInsightPreview);
  const tags = insights.flatMap((insight) => insight.tags).filter(onlyUnique);

  return (
    <Base location={location}>
      <Sides>
        <div className="pt-[3.5rem] lg:pt-0">
          <Header tags={['All', ...tags]} />
          <Board total={total} currentPage={currentPage} insights={insights} />
        </div>
      </Sides>
    </Base>
  );
};

export default Insights;

export const pageQuery = graphql`
  query insightListQuery($skip: Int!, $limit: Int!, $language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "insights" } }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
          alttext
          summary
          thumbnail {
            publicURL
          }
          tags {
            name
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 Insights" />;
